import React, { Suspense} from "react";
import {BrowserRouter} from 'react-router-dom'
import { LoadingComponent } from "./Components/utils";
import AnimatedRoutes from "./Components/animatedRoutes/AnimatedRoutes";

const BaseRoutes = () => {

  return (
      <Suspense fallback={<LoadingComponent />}>
          <BrowserRouter>
             <AnimatedRoutes/>
          </BrowserRouter>
      </Suspense>
  );
};

export default BaseRoutes;