import {fetchDelete, fetchGet, fetchPatch, fetchPost} from './CommonServiceUtils';
import AppContext from '../AppContext';

const BASE_URL = `${AppContext.baseUrl}shoutoutaiservice/helpdesk`;

const getHelpdeskAccounts = () => {
    return fetchGet(`${BASE_URL}/accounts`);
};
const getCreatedInboxes = ({accountId}) => {
    return fetchGet(`${BASE_URL}/inboxes/${accountId}`);
};
const getConnectedBotsToInboxes = ({accountId, inboxId}) => {
    return fetchGet(`${BASE_URL}/inboxes/${accountId}/agentbot/${inboxId}`);
};
const getHelpdeskAgents= ({accountId}) => {
    return fetchGet(`${BASE_URL}/agent/${accountId}`);
};
const  createNewAgent = (payload,accountId) => {
    return fetchPost(`${BASE_URL}/agent/${accountId}`, payload)
};

const editAgent = ({accountId, agentId}, payload) => {
    return fetchPatch(`${BASE_URL}/agent/${accountId}/${agentId}`, payload)
}

const archiveAgent = ({accountId, agentId, email}) => {
    return fetchDelete(`${BASE_URL}/agent/${accountId}/${agentId}/${email}`)
}

const logInHelpdeskAccount = () => {
    return fetchGet(`${BASE_URL}/auth/login`);
};

const getHelpdeskAgentBots= ({accountId}) => {
    return fetchGet(`${BASE_URL}/agent-bot/connect/${accountId}`);
};

const connectAgentBot = payload => {
    return fetchPost(`${BASE_URL}/agent-bot/connect`, payload)
};

const getConversationCount = ({accountId}) => {
    return fetchGet(`${BASE_URL}/conversations/meta/${accountId}`)
}

const disconnectFlowBot= (payload) => {
    return fetchPost(`${BASE_URL}/agent-bot/disconnect`, payload)
};

export {
    getCreatedInboxes,
    getHelpdeskAccounts,
    createNewAgent,
    logInHelpdeskAccount,
    getHelpdeskAgents,
    getHelpdeskAgentBots,
    connectAgentBot,
    editAgent,
    archiveAgent,
    getConversationCount,
    disconnectFlowBot,
    getConnectedBotsToInboxes,
};
