import {fetchGet, fetchPost} from './CommonServiceUtils';
import AppContext from '../AppContext';

const BASE_URL = `${AppContext.baseUrl}shoutoutaiservice/payment`;

const createPaymentMethod = payload => {
    return fetchPost(`${BASE_URL}/method/attach`, payload)
};
const getPaymentMethods = () => {
    return fetchGet(`${BASE_URL}/methods`);
};

const createPaymentSubscription = payload => {
    return fetchPost(`${BASE_URL}/create/subscription`, payload)
};

export {createPaymentMethod,getPaymentMethods,createPaymentSubscription};