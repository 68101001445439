import React from 'react';
import {Card,Badge, Row, Col} from 'react-bootstrap';
import moment from "moment";
import Avatar from 'react-avatar';

const statusColorMap = {
    'LIVE' : {
        color: 'success',
        text: 'Live'
    },
    'DRAFT': {
        color: 'warning',
        text: 'Draft'
    },
    'INACTIVE':{
        color: 'danger',
        text: 'Inactive'
    }
}

const BotListCard = ({botDetails}) => {

    return (
            <>
                <Card 
                    className='bot-list-card' 
                >
                    <Row className='mt-5 bot-card'>
                        <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                            <div className='align-middle'>
                                <Avatar name={botDetails?.name} size={40} round="20px" />
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                            <h4 className='align-middle fw-bold'>
                                {botDetails?.name}
                            </h4>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={3} xs={3}>
                            <h6 className='mb-3'>
                                Created On: {moment(botDetails?.createdOn).format("LLL")}
                            </h6>
                            <h6>
                                Updated On: {moment(botDetails?.updateddOn).format("LLL")}
                            </h6>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                            <h6>
                                Language: {botDetails?.language}
                            </h6>
                        </Col>
                        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                            <h6>
                                Invocation Count: {botDetails?.invocationCount || 0}
                            </h6>
                        </Col>
                        <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1}>
                            <Badge bg={statusColorMap[botDetails.status]?.color} className='align-middle'>
                                {statusColorMap[botDetails.status]?.text}
                            </Badge>
                        </Col>
                    </Row>
                </Card>
             </>
        )
};

export default BotListCard;