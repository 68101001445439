import React from "react";
import { ToastContainer, Slide } from "react-toastify";
import {KeycloakContextProvider, UserContextProvider,PaymentContextProvider} from "./Contexts";
import BaseRoutes from "./BaseRoutes";
import "react-toastify/dist/ReactToastify.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import StripeWrapper from "../src/Components/shared/payment/StripeWrapper";
import "./i18n";
import './LocalTheme.scss';

const App = () => {
  return (
    <div className="h-100">
      <KeycloakContextProvider>
        <UserContextProvider>
            <StripeWrapper>
                <PaymentContextProvider>
                    <ToastContainer
                        position="top-center"
                        transition={Slide}
                        theme="colored"
                        pauseOnFocusLoss={false}
                    />
                    <BaseRoutes />
                </PaymentContextProvider>
            </StripeWrapper>
        </UserContextProvider>
      </KeycloakContextProvider>
    </div>
  );
};

export default App;
