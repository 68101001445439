import CustomerService from "../../assets/images/customer-service.svg";
import ProgramFlowchart from "../../assets/images/program-flowchart.svg";
import TechnicalSupport from "../../assets/images/technical-support.svg";
const FreeAccountBenefits =[
    "One Agent Access for Omnichannel Inbox",
    "Access for Social Media Inboxes",
    "Contact Management",
    "Agent Private Notes",
    "Canned Responses",
    "Ongoing Campaigns",
    "Reports",
];

const DashboardTypes ={
    agentTool:"agentTool",
    flowBot:"flowBot",
    aiBot:"aiBot"
};
const ShoutOUTAIFacilities=[
    {image:CustomerService,navigate:"/",dashboardType:DashboardTypes.agentTool, content:"I want a tool that can bring all my social media accounts together in one place, making it easier for me to manage them all efficiently."},
    {image:ProgramFlowchart,dashboardType:DashboardTypes.flowBot, navigate:"/", content:"I want a simple flowchart bot that seamlessly connects to my agent tool. This way, I can handle all my social media conversations conveniently from a single location."},
    {image:TechnicalSupport, dashboardType:DashboardTypes.aiBot, navigate:"/",content:"I want an Al-powered chatbot that integrates with my agent tool, allowing me to effortlessly manage all my social media conversations in one centralized platform."},
];

export {FreeAccountBenefits,ShoutOUTAIFacilities,DashboardTypes};