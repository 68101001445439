import React, {
    useCallback, useContext,
    useEffect,
    useReducer,
} from "react";
import { toast } from "react-toastify";
import {KeycloakContext} from "./keycloakAuthServiceContext";
import {DashboardTypes} from "../Components/Data";
import {getHelpdeskAccounts} from "../Services";


// This context is created assuming that we will need to keep some user data in this application to sync with the main dashboard 

const UserContext = React.createContext();

const UserContextActions = {
    SET_INIT_SYSTEM_ERROR_MESSAGE: "setInitSystemErrorMsg",
    SET_USER_CONFIG_LOADED: "setUserConfigLoaded",
    SET_LOGGED_USER: "setLoggedUser",
    SET_DASHBOARD_TYPE: "setDashboardType",
    SET_FLOW_PLAN_STATUS: "setFlowPlanStatus",
    SET_HELPDESK_ACCOUNTS: "setHelpdeskAccounts",
};

const initialState = {
    systemInitFailMessage: null,
    userConfigLoaded: false,
    loggedUser: null,
    isRunningSystemInit: false,
    isAuth : true,
    dashboardType:DashboardTypes.agentTool,
    userHasFlowPlan:false,
    userHasIntelliBotPlan:false,
    userHasHelpdeskAccounts:false,
    helpdeskAccounts:{},
};

const reducer = (state, action) => {
    switch (action.type) {
        case UserContextActions.SET_INIT_SYSTEM_ERROR_MESSAGE: {
            return {
                ...state,
                systemInitFailMessage: action.message,
                isRunningSystemInit: false,
            };
        }
        case UserContextActions.SET_LOGGED_USER: {
            return {
                ...state,
                loggedUser: action.userInfo,
            };
        }
        case UserContextActions.SET_DASHBOARD_TYPE: {
            return {
                ...state,
                dashboardType: action.dashboardType,
            };
        }
        case UserContextActions.SET_FLOW_PLAN_STATUS: {
            return {
                ...state,
                userHasFlowPlan: action.userHasFlowPlan,
            };
        }
        case UserContextActions.SET_HELPDESK_ACCOUNTS: {
            return {
                ...state,
                helpdeskAccounts: action.helpdeskAccounts,
                userHasHelpdeskAccounts:action.helpdeskAccounts?.hasOwnProperty("id"),
            };
        }
        default:
            return state;
    }
};

const constantMock = window.fetch;

const UserContextProvider = (props) => {
    const {keycloakLogout, loadUserProfile, isAuth ,getAuthToken,initKeycloak,updateAuthInfo} = useContext(KeycloakContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    window.fetch = async function () {
        const body = arguments[1];
        if (body.headers["x-auth"]) {
            delete body.headers["x-auth"];
            body.headers["Authorization"] = `Bearer ${getAuthToken()}`;
        }
        const response = await constantMock.apply(this, arguments);

        if (response.status === 401) {
            keycloakLogout();
        }

        return response;
    };

    const setDashboardType = useCallback(async (dashboardType) =>dispatch({ type: UserContextActions.SET_DASHBOARD_TYPE, dashboardType }), [ dispatch]);

    const setFlowPlanStatus = useCallback(async (userHasFlowPlan) =>dispatch({ type: UserContextActions.SET_DASHBOARD_TYPE, userHasFlowPlan }), [ dispatch]);

    const loadUserInfo = useCallback(async () => {
        try {
            const userInfo = await loadUserProfile();
            dispatch({ type: UserContextActions.SET_LOGGED_USER, userInfo });
        } catch (e) {
            console.error(e);
            toast.error("Failed to load user profile!");
        }
    }, [loadUserProfile, dispatch]);

    const loadHelpdeskAccounts = useCallback(async () => {
        try {
            const helpdeskAccounts = await getHelpdeskAccounts();
            dispatch({ type: UserContextActions.SET_HELPDESK_ACCOUNTS, helpdeskAccounts:(helpdeskAccounts?.length!==0?helpdeskAccounts[0]:{})});
        } catch (e) {
            console.error(e);
            toast.error("Failed to load user helpdesk accounts");
        }
    }, [ dispatch]);

    useEffect(() => {
        (async () => {
            if (isAuth) {
                loadUserInfo();
                loadHelpdeskAccounts();
            }
        })();
        // eslint-disable-next-line
    }, [isAuth]);


    useEffect(() => {
       if(window.sessionStorage.getItem("dashboardType")){
           setDashboardType(window.sessionStorage.getItem("dashboardType"));
       }
        // eslint-disable-next-line
    }, []);


    const value = {
        ...state,
        username: state.loggedUser?.name,
        email: state.loggedUser?.email,
        keycloakLogout,
        setDashboardType,
        setFlowPlanStatus,
        isAuth,
        initKeycloak,
        updateAuthInfo
    };

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    );
};

const UserContextConsumer = UserContext.Consumer;

export {
    UserContext,
    UserContextProvider,
    UserContextConsumer,
    UserContextActions,
};
