import React from 'react'
import PropTypes from 'prop-types'
import ShIcon from '../../icons/ShIcon';
import { useWizard } from 'react-use-wizard'
import { UilCheck } from '@iconscout/react-unicons';
import './WizardHeader.scss'
const WizardHeader = ({ steps }) => {
  const { activeStep } = useWizard();
  return (
    <div className='d-flex wizard-header mb-4 justify-content-around align-items-center'>
      {steps.map(({ name, icon }, index) => {
          const isActive= activeStep === index;
        return (
            <>
                <div key={name} className="d-flex justify-content-between align-items-center">
                    {!isActive&&<div className="step-progress mx-3">{index+1}</div>}
                    {isActive&&<ShIcon
                        icon={isActive?UilCheck:icon}
                        size="sm"
                        className="mx-3 step-progress"
                    />}
                    <div>{name}</div>
                </div>
                {index < steps.length - 1 && (
                    <div className='line-container'>
                        <div className="horizontal_line"/>
                    </div>
                )}
            </>
        )
      })}
    </div>
  )
}

WizardHeader.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired
    })
  ).isRequired
}

export default WizardHeader
