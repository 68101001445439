import { fetchDelete, fetchGet, fetchPatch, fetchPost } from './CommonServiceUtils';
import AppContext from '../AppContext';

const BASE_URL = `${AppContext.baseUrl}shoutoutaiservice/users`;

const createAccount = payload => {
    return fetchPost(`${BASE_URL}/createaccount`, payload)
};

const getUserDetails = () => {
    return fetchGet(`${BASE_URL}/`)
};

const editProfile = payload => {
    return fetchPatch(`${BASE_URL}/editprofile`, payload)
}

const deleteAccount = () => {
    return fetchDelete(`${BASE_URL}/delete`)
}

export {createAccount, getUserDetails, editProfile, deleteAccount};
