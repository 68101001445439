import Flowy from "../../assets/images/chatbot-support.png";

const IntelliBotMainSection={
    mainHeader:"Elevate Conversations with Our Intelligent AI Chatbot Experience!",
    mainDescription:"Introducing InteliBot, the ultimate conversational AI powered by cutting-edge NLP technology. It seamlessly understands multiple languages, adapts to diverse domains, offers agent-to-bot transfers based on customer preference, and effortlessly integrates with your core systems for unparalleled efficiency and customer satisfaction.",
    description: "Ready to take your customer support to the next level? Fill out the form below and our sales team will reach out to you shortly.",
    mainImage:Flowy
}

const IntelliBotGuide=[
    {number:"1",boldText:"Demo Credentials:",normalText:"We have sent an email to your registered account with the demo credentials. Please check your inbox and use these credentials to explore our bot and agent tool. Contact info@shoutout.ai if you didn't get the email."},
    {number:"2",boldText:" Test the Bot and Agent Tool:",normalText:"Once you have the demo credentials, log in to the platform and navigate to the \"Bot and Agent\" section. Take some time to familiarize yourself with the features and functionalities available. Feel free to test the bot's responses and experiment with the agent tool."},
    {number:"3",boldText:"Account Setup:",normalText:"After you have had a chance to try out the demo, we will set up your official account. This will grant you access to a personalized dashboard where you can build and customize your bot according to your specific requirements."},
    {number:null,boldText:null,normalText:"We're here to support you throughout the entire process. If you have any questions or need assistance, please don't "},
];

export {
    IntelliBotMainSection,
    IntelliBotGuide
}