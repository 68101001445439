import {fetchPost} from './CommonServiceUtils';
import AppContext from '../AppContext';

const BASE_URL = `${AppContext.baseUrl}shoutoutaiservice/intellibot`;

const  saveUserDetails = (payload) => {
    return fetchPost(`${BASE_URL}`, payload)
};

export {
    saveUserDetails
};