import React from 'react';

const Heading = ({text}) => {
  return (
    <h1 className='font-weight-bold'>
        {text}
    </h1>
  )
}

export default Heading