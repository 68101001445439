import React, { useContext, useEffect } from 'react'
import { UserContext } from './Contexts';
import { Outlet } from 'react-router-dom'
import { LoadingComponent } from "./Components/utils";


const AuthRoute = () => {
    const { isAuth,initKeycloak,updateAuthInfo} = useContext(UserContext);

    useEffect(() => {
        if (!isAuth) initKeycloak(updateAuthInfo);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth,updateAuthInfo]);

    return isAuth ? <Outlet/> : <LoadingComponent />

        }
export default AuthRoute;
