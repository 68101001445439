import React, { useCallback, useState, useEffect } from "react"
import BaseLayout from "../../Layout/BaseLayout"
import { Heading } from "../utils"
import { Button, Image } from "react-bootstrap"
import { UilPlus } from "@iconscout/react-unicons"
import flowy_landing_page from "../../assets/images/flowy_landing_page.png"
import { toast } from "react-toastify"
import AppContext from "../../AppContext";
import {getPublishedFlowyBots} from '../../Services';
import PublishedBotsView from "./PublishedBotsView";

import "./FlowyLandingPage.scss";

let defaultSkip=0, defaultLimit=100;

export const FlowyLandingPage = () => {

  const [botFlows, setBotFlows] = useState([]);
  const [invocationCount, setInvocationCount] = useState(0);

  const getBotFlows = useCallback(async()=> {

    try{

      const data = await getPublishedFlowyBots({skip:defaultSkip, limit:defaultLimit});
      setBotFlows(data?.[0].items);

      const totalInvocationCount = data?.[0].items.reduce((total = 0, obj) => total + obj?.invocationCount, 0);

      setInvocationCount(totalInvocationCount);
    }catch(e){
      console.error('Failed to load published bots');
      toast.error(
        'Failed to load published bots!'
      );
    }
  },[setBotFlows, setInvocationCount]);

  const handleNavigateFlowy = useCallback(() => {
        const flowyURL = `${AppContext.flowyUrl}`;
        if (flowyURL) {
          window.open(flowyURL, "_blank");
        } else {
            toast.error("Sorry, we couldn't navigate to Flowy at the moment. Please try again later.")
        }
      }, []);

    useEffect(() => {
    
        getBotFlows();
        // eslint-disable-next-line
      }, []);

    const navigateToOnCreateBotFlow = useCallback(() => {
      const flowCreateURL = `${AppContext.flowyUrl}flow-create`
      if (flowCreateURL) {
        window.open(flowCreateURL, "_blank")
      } else {
        toast.error(
          "Sorry, we couldn't navigate to the flow creation page at the moment. Please try again later."
        )
      }
    },[])
      
  return (
    <div className="flowy-landing-page">
      <BaseLayout
        containerClassName={"flowy-page"}
        topLeft={<Heading text="Flowy" />}
        topRight={
          <div>
            <div className="me-3">
              <span className="invocation-count"> Total Invocations: {invocationCount ? invocationCount : 0}</span>
              <Button variant={"primary"} size="lg" className="white-text-button" onClick={handleNavigateFlowy}>
                Go to Flowy
              </Button>
            </div>
          </div>
        }
        bottom={
          <>
            {
              botFlows && botFlows.length > 0 ?
                <PublishedBotsView bots={botFlows}/>
                :
          <div className="flowy-content">
            <Image className="flowy-image" src={flowy_landing_page} rounded />
            <h3 className="flowy-landing-text">
              Discover our easy-to-use non-intelligent flow-based bot!
            </h3>
            <div className="text-center">
              <p>
                Enhance your bot's appeal with images, carousels, and buttons.
                Create a standardout, user-friendly experience, ensuring smooth
                information delivery to captivate a assist customers
                effectively.
              </p>
            </div>
            <div className="bot-create-form">
              <Button
                variant={"primary"}
                onClick={navigateToOnCreateBotFlow}
                className="white-text-button"
                size="lg"
              >
                <div className="my-2 mx-4">
                  <UilPlus size="20" /> Create a Flow Bot
                </div>
              </Button>
            </div>
          </div>
        }
        </>
        }
      />
    </div>
  )
}
