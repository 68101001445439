import React, {useCallback, useContext, useEffect, useReducer,} from "react";
import {City, Country, State} from "country-state-city";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {toast} from "react-toastify";
import {createPaymentMethod, createPaymentSubscription, getPaymentMethods} from "../Services";
import {UserContext} from "./userContext";
import {ActiveScreens, PricingGroups} from "../Components/Data";
import {getProductByPricingGroup} from "../Services/PackagesService";



function parseForSelect(arr) {
    return arr.map((item) => ({
        label: item.name,
        value: item.isoCode ? item.isoCode : item.name,
    }));
}
const PaymentContext = React.createContext();

const addNewPayState = {
    city:[],
    country:[],
    state:[],
    cardHolderName:"",
    AddressLineOne:"",
    AddressLineTwo:"",
    postalCode:"",
    validated:false,
    isSavingPayment:false,
    isPaymentSucceeded:false,
    activeScreen:ActiveScreens.LIST_PAYMENT_METHODS,
    selectedMethod:{},
    packageInfo:{}
};

const initialState = {
    userHasPaymentMethods:false,
    paymentMethods:[],
    countries: [],
    states: [],
    cities: [],
   ...addNewPayState
};

const PaymentContextActions = {
    SET_NEW_VALUE: "setNewValue",
    SET_PAYMENT_METHODS: "setPaymentMethods",
    SET_LOCATIONS: "setLocations",
    SET_VALIDATED: "setValidated",
    SET_ACTIVE_PAYMENT_SCREEN: "setActivePaymentScreen",
    SET_IS_SAVING_PAYMENT: "setIsSavingPayment",
    SET_IS_PAYMENT_SUCCEEDED: "setIsPaymentSucceeded",
    SET_SELECTED_METHOD:"setSelectedMethod",
    SET_PACKAGE_INFO:"setPackageInfo",
    RESET_PAY_STATE:"resetPayState"
};

const reducer = (state, action) => {
    switch (action.type) {
        case PaymentContextActions.SET_VALIDATED: {
            return {
                ...state,
                validated:action.validated
            };
        }
        case PaymentContextActions.RESET_PAY_STATE: {
            return {
                ...state,
                ...addNewPayState
            };
        }
        case PaymentContextActions.SET_PACKAGE_INFO: {
            return {
                ...state,
                packageInfo:action.packageInfo
            };
        }
        case PaymentContextActions.SET_NEW_VALUE: {
            return {
                ...state,
                [action.name]:action.value,
            };
        }
        case PaymentContextActions.SET_SELECTED_METHOD: {
            return {
                ...state,
                selectedMethod:action.selectedMethod,
            };
        }
        case PaymentContextActions.SET_LOCATIONS: {
            return {
                ...state,
                [action.attribute]:action.locationData
            };
        }
        case PaymentContextActions.SET_ACTIVE_PAYMENT_SCREEN: {
            return {
                ...state,
                activeScreen:action.activeScreen
            };
        }
        case PaymentContextActions.SET_IS_PAYMENT_SUCCEEDED: {
            return {
                ...state,
                isPaymentSucceeded:action.isPaymentSucceeded
            };
        }
        case PaymentContextActions.SET_IS_SAVING_PAYMENT: {
            return {
                ...state,
                isSavingPayment:action.isSavingPayment
            };
        }
        case PaymentContextActions.SET_PAYMENT_METHODS: {
            return {
                ...state,
                paymentMethods:action.paymentMethods,
                userHasPaymentMethods:action.userHasPaymentMethods,
            };
        }
        default:
            return state;
    }
};

const PaymentContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {isAuth} = useContext(UserContext);
    const stripe = useStripe();
    const elements = useElements();

    const onChangeHandler = useCallback( (event) => {
        event.stopPropagation();
        dispatch({type: PaymentContextActions.SET_NEW_VALUE, name:event.target.name,value:event.target.value});
    }, [dispatch]);

    const handleSelectCard = useCallback( (method) => {
        dispatch({type: PaymentContextActions.SET_SELECTED_METHOD, selectedMethod:method});
        dispatch({type: PaymentContextActions.SET_ACTIVE_PAYMENT_SCREEN,activeScreen:ActiveScreens.PAYMENT_SCREEN});
    }, [dispatch]);

    const onChangeLocationInfo = useCallback( ({ attribute,locationData}) => {
        dispatch({type: PaymentContextActions.SET_LOCATIONS,attribute,locationData});
    }, [dispatch]);

    const onChangeSelectCountry = useCallback( (country) => {
        const states = State.getStatesOfCountry(country[0]?.value);
        dispatch({type: PaymentContextActions.SET_LOCATIONS,attribute:"country",locationData:country});
        dispatch({type: PaymentContextActions.SET_LOCATIONS,attribute:"states",locationData:parseForSelect(states)});
        dispatch({type: PaymentContextActions.SET_LOCATIONS,attribute:"state",locationData:[]});
        dispatch({type: PaymentContextActions.SET_LOCATIONS,attribute:"city",locationData:[]});
    }, [dispatch]);

    const handleSelectState = useCallback( (selectedState) => {
        const cities = City.getCitiesOfState(state.country[0].value, selectedState[0].value);
        dispatch({type: PaymentContextActions.SET_LOCATIONS,attribute:"state",locationData:selectedState});
        dispatch({type: PaymentContextActions.SET_LOCATIONS,attribute:"cities",locationData:parseForSelect(cities)});
        dispatch({type: PaymentContextActions.SET_LOCATIONS,attribute:"city",locationData:[]});
    }, [dispatch,state]);

    const handleSelectCity = useCallback( (city) => {
        dispatch({type: PaymentContextActions.SET_LOCATIONS,attribute:"city",locationData:city});
    }, [dispatch]);

    const setActivePaymentScreen = useCallback( (activeScreen) => {
        dispatch({type: PaymentContextActions.SET_ACTIVE_PAYMENT_SCREEN,activeScreen});
    }, [dispatch]);

    const listPaymentMethods = useCallback(async ()  => {
      try {
          const response = await getPaymentMethods();
          if(response?.data?.length!==0){
              dispatch({type: PaymentContextActions.SET_PAYMENT_METHODS,paymentMethods:response.data,userHasPaymentMethods:true});
          }
      }  catch (e) {
          console.error(e);
          toast.error("Failed to load payment methods");
      }
    },[dispatch]);

    const resetPayState = useCallback( () => {
        dispatch({type: PaymentContextActions.RESET_PAY_STATE});
    }, [dispatch]);


    const getProduct = useCallback(async (pricingGroup)  => {
        try {
            const response = await getProductByPricingGroup({pricingGroup});
            dispatch({type: PaymentContextActions.SET_PACKAGE_INFO,packageInfo:response});
        }  catch (e) {
            console.error(e);
            toast.error("Failed to load payment methods");
        }
    },[dispatch]);

    const onSubmitPaymentMethod = useCallback(async () => {
        if (!stripe || !elements) {
            return;
        }
        const card = elements.getElement(CardElement);
        if (card._invalid||card._empty) {
            dispatch({type: PaymentContextActions.SET_VALIDATED, validated:true});
        } else {
            const billingDetails = {
                name: state.cardHolderName,
                address: {
                    country: state.country[0].value,
                    state: state.state[0].value,
                    city: state.city[0].value,
                    line1: state.AddressLineOne,
                    line2:state.AddressLineTwo,
                    postal_code:state.postalCode,
                },
            };
            try{
            dispatch({type: PaymentContextActions.SET_IS_SAVING_PAYMENT, isSavingPayment:true});
            const response =await stripe.createPaymentMethod({
                type: "card",
                billing_details: billingDetails,
                card: card}
            );
            const paymentMethod =await createPaymentMethod({paymentMethod:response.paymentMethod});
            listPaymentMethods();
            handleSelectCard(paymentMethod);
            }catch(err){
                toast.error(err.message||"Couldn't processed new payment method creation. Please try again in a while. If problem persists, please contact support");
            }finally {
                dispatch({type: PaymentContextActions.SET_IS_SAVING_PAYMENT, isSavingPayment:false});
            }
        }
    },[dispatch,state,stripe,elements,listPaymentMethods,handleSelectCard]);

    const createSubscription = useCallback(async (subscriptionHistory) => {
        try {
            dispatch({type: PaymentContextActions.SET_IS_SAVING_PAYMENT, isSavingPayment:true});
            const subscriptionResponse= await createPaymentSubscription({
                pricingGroup: PricingGroups.HELPDESK_AGENT,
                subscriptionHistory: subscriptionHistory
            });
            const confirmPayment = await stripe.confirmCardPayment(subscriptionResponse.clientSecret,{
                payment_method:state.selectedMethod.id,
            });
            if(confirmPayment?.paymentIntent?.status==="succeeded"){
                dispatch({type: PaymentContextActions.SET_IS_PAYMENT_SUCCEEDED, isPaymentSucceeded:true});
            }else {
                toast.error("Couldn't processed payment subscription. Please try again in a while. If problem persists, please contact support")
            }
        }catch (e) {
            toast.error(e.message)
        }finally {
            dispatch({type: PaymentContextActions.SET_IS_SAVING_PAYMENT, isSavingPayment:false});
        }
    },[dispatch,state,stripe]);

    useEffect(() => {
        const allCountry = Country.getAllCountries();
        onChangeLocationInfo({attribute:"countries",locationData:parseForSelect(allCountry)});
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        (async () => {
            if (isAuth) {
                listPaymentMethods();
            }
        })();
        // eslint-disable-next-line
    }, [isAuth]);


    const value = {
        ...state,
        setActivePaymentScreen,
        onChangeHandler,
        onChangeLocationInfo,
        onChangeSelectCountry,
        handleSelectState,
        handleSelectCity,
        onSubmitPaymentMethod,
        handleSelectCard,
        getProduct,
        createSubscription,
        resetPayState
    };
//console.log(value)
    return (
        <PaymentContext.Provider value={value}>
            {props.children}
        </PaymentContext.Provider>
    );
};

const PaymentContextConsumer = PaymentContext.Consumer;

export {
    PaymentContext,
    PaymentContextProvider,
    PaymentContextConsumer,
    PaymentContextActions,
};
