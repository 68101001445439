import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {WavingHand} from "./Components/Data/Images";
import { UilHeadphones,UilCloudComputing,UilLinkBroken,UilRocket   } from '@iconscout/react-unicons';
import AppContext from './AppContext';


const resources = {
    agentTool: {
        translation: {
             dashboard:{
                 mainHeader:"Dashboard",
                 sectionOne:{
                     mainImage:WavingHand,
                     mainDescription:"We are excited to get you started. Our quick start guide will help you create your agent tool on ShoutOUT.",
                     accordionMapExist:true,
                     accordionMap:[
                         {
                             accordionIcon:UilHeadphones,
                             accordionHeader:"Create Channels in Agent Tool - Helpdesk",
                             accordionDescription:"Create inboxes for your preferred channels. You can find the instructions for creating an inbox for each channel in the Helpdesk > Inbox section.",
                             accordionActionExist:true,
                             accordionActionName:"Go to Agent Tool",
                             accordionActionLink: `${AppContext.helpdeskUrl}`
                         }
                     ]
                 }
             }
        }
    },
    flowBot: {
        translation: {
            dashboard:{
                mainHeader:"Dashboard",
                sectionOne:{
                    mainImage:WavingHand,
                    mainDescription:"We are excited to get you started. Our quick start guide will help you create your chatbot on ShoutOUT",
                    accordionMapExist:true,
                    accordionMap:[
                        {
                            accordionIcon:UilRocket ,
                            accordionHeader:"Subscribe a Flowy Package",
                            accordionDescription:"Select your ideal monthly plan and create a flow bot with Flowy. Subscribe today and embrace seamless prodcutivity.",
                            accordionActionExist:true,
                            accordionActionName:"Go to Flowy",
                            accordionActionLink: `${AppContext.controlHubUrl}home/flowy`
                        },
                        {
                            accordionIcon:UilCloudComputing,
                            accordionHeader:"Design the Conversational Flow",
                            accordionDescription:"Design a Flowy chatbot for efficient communication. Once done, publish it to connect with your chosen channel for optimal performance.",
                            accordionActionExist:true,
                            accordionActionName:"Go to Flowy Dashboard",
                            accordionActionLink: `${AppContext.flowyUrl}`
                        },
                        {
                            accordionIcon:UilHeadphones,
                            accordionHeader:"Create Channels in Agent Tool - Helpdesk",
                            accordionDescription:"Create inboxes for your preferred channels. You can find the instructions for creating an inbox for each channel in the Helpdesk > Inbox section.",
                            accordionActionExist:true,
                            accordionActionName:"Go to Helpdesk",
                            accordionActionLink: `${AppContext.helpdeskUrl}`
                        },
                        {
                            accordionIcon:UilLinkBroken ,
                            accordionHeader:"Connect the Designed Flow With Your Preffered Channel",
                            accordionDescription:"You're all set to launch your flowy bot! Go the Helpdesk to effortlessly connect the bot with your preffered channels.",
                            accordionActionExist:true,
                            accordionActionName:"Go to Helpdesk",
                            accordionActionLink: `${AppContext.helpdeskUrl}`
                        }
                    ]
                }
            }
        }
    },
    aiBot: {
        translation: {
            dashboard: {
                mainHeader: "Dashboard",
                sectionOne: {
                    mainImage: WavingHand,
                    mainDescription: "We are excited to get you started. Our quick start guide will help you create your chatbot on ShoutOUT",
                    accordionMapExist: true,
                    accordionMap:[
                        {
                            accordionIcon:UilRocket ,
                            accordionHeader:"Power Up Your Business Operations with AI Chatbot Integration!",
                            accordionDescription:"Interested in an AI-based chatbot? Just fill out the form on Intellibot, and our sales team will touch you soon!",
                            accordionActionExist:true,
                            accordionActionName:"Go to Intellibot",
                            accordionActionLink:`${AppContext.intellibotUrl}`
                        }
                    ]
                }
            }
        }
    },
};


i18n.use(initReactI18next).init({
        fallbackLng: "agentTool",
        resources,
        lng: "agentTool",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
