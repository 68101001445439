import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './NoHelpdeskAccountFoundPage.scss';

function NoHelpdeskAccountFoundPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const errorMessage = queryParams.get('error');
  const navigate = useNavigate();

  return (
    <div className="no-helpdesk-account-page">
      <h1>No Helpdesk Account Found</h1>
      {errorMessage && <p className="error">{errorMessage}</p>}
      <p>Please login again or contact support team.</p>
      <Button onClick={() => navigate('/')} variant="primary">Login</Button>
    </div>
  );
}

export default NoHelpdeskAccountFoundPage;


