import React, {lazy, useCallback, useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import AuthRoute from "../../AuthRoute";
import NoHelpdeskAccountFoundPage from "../helpdesk/NoHelpdeskAccountFoundPage/NoHelpdeskAccountFoundPage";
import { FlowyLandingPage } from "../flowy/FlowyLandingPage";
const BillingPage = lazy(() => import("../billing/BillingPage"));
const DashboardPage = lazy(() => import("../dashboard/DashboardPage"));
const HelpdeskPage = lazy(() => import("../helpdesk/HelpdeskPage"));
const IntelliBotPage = lazy(() => import("../intelliBot/IntelliBotPage"));
const ProfilePage = lazy(() => import("../profile/ProfilePage"));
const Home = lazy(() => import("../home/Home"));
const ErrorBoundary = lazy(() => import("../../ErrorBoundary"));
// const LandingPage = lazy(() => import("../landingPage/landingPage"));
const SignUp = lazy(() => import("../signUp/SignUp"));
const UsersPage = lazy(() => import("../users/UsersPage"));

const AnimatedRoutes=()=>{
    const location = useLocation();
    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransitionStage] = useState("fadeIn");

    const handleAnimationEnd = useCallback(() => {
        if (transitionStage === "fadeOut") {
            setTransitionStage("fadeIn");
            setDisplayLocation(location);
        }
    }, [transitionStage,setTransitionStage,setDisplayLocation,location]);

    useEffect(() => {
        if (location !== displayLocation) setTransitionStage("fadeOut");
    }, [location, displayLocation]);

    return(
        <div onAnimationEnd={handleAnimationEnd}>
            <Routes location={displayLocation}>
                <Route path="/" element={<AuthRoute />}>
                    {
                        // <Route element={<LandingPage transitionStage={transitionStage} />} path="/" errorElement={<ErrorBoundary />}/>
                    }
                    <Route path="/" element={<Home transitionStage={transitionStage} />} errorElement={<ErrorBoundary />}>
                        <Route path="" element={<DashboardPage />} />
                        <Route path="helpdesk" element={<HelpdeskPage />} />
                        <Route path="flowy" element={<FlowyLandingPage />} />
                        <Route path="intellibot" element={<IntelliBotPage />} />
                        <Route path="billing" element={<BillingPage />} />
                        <Route path="users" element={<UsersPage />} />
                        <Route path="profile" element={<ProfilePage />} />
                    </Route>
                    <Route path="not-found-helpdesk" element={<NoHelpdeskAccountFoundPage />} />
                </Route>
                <Route exact path='/signUp' element={<SignUp transitionStage={transitionStage}/>}/>
            </Routes>
        </div>
    )
}

export default AnimatedRoutes;