import visa from "../../assets/images/cards/visa.png";
import americanexpress from "../../assets/images/cards/americanexpress.png";
import dinersclub from "../../assets/images/cards/dinersclub.jpg";
import discover from "../../assets/images/cards/discover.jpg";
import elo from "../../assets/images/cards/elo.png";
import hiper from "../../assets/images/cards/hiper.png";
import jcb from "../../assets/images/cards/jcb.png";
import mastercard from "../../assets/images/cards/mastercard.png";
import mir from "../../assets/images/cards/mir.png";
import unionpay from "../../assets/images/cards/unionpay.png";
const GetCardImage = (type) =>{
    switch (type) {
        case "visa":
            return visa;
        case "mastercard":
            return mastercard;
        case "amex":
            return americanexpress;
        case "diners club":
            return dinersclub;
        case "discover":
            return discover;
        case "jcb":
            return jcb;
        case "unionpay":
            return unionpay;
        case "maestro":
            return mastercard;
        case "mir":
            return mir;
        case "elo":
            return elo;
        case "hiper":
            return hiper;
        case "hipercard":
            return hiper;
        default:
            return visa;
    }
}

const ActiveScreens={
    ADD_PAY_METHOD:"addPayMethod",
    LIST_PAYMENT_METHODS:"ListPaymentMethods",
    PAYMENT_SCREEN:"paymentScreen",
}
const PricingGroups ={
    HELPDESK_AGENT:"HELPDESK_AGENT",
}

export {GetCardImage,ActiveScreens,PricingGroups}