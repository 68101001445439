import Flowy from "../../assets/images/flowy.svg";

const FlowyMainSection={
    mainHeader:"Discover our easy-to-use non-intelligent flow-based bot!",
    mainDescription:"   When designing your bot's flow, you have the power to incorporate various components such as eye-catching images, dynamic carousels,and interactive buttons. It's a simple way to make your bot visually appealing and user-friendly. With this customization, you can ensure a smooth and enjoyable experience for your customers as they navigate through the bot.Design a bot that stands out, grabs attention, and effortlessly provides the information that customers need.",
    mainImage:Flowy
}

const FlowyPricingPlan=[
    {
        packageStatus:true,
        pricingPlan:"Starter",
        plansPrice:"$9.99",
        planDescription:"Invocations: Up to 100"
    },
    {
        packageStatus:false,
        pricingPlan:"Starter",
        plansPrice:"$9.99",
        planDescription:"Invocations: Up to 100"
    },
    {
        packageStatus:false,
        pricingPlan:"Starter",
        plansPrice:"$9.99",
        planDescription:"Invocations: Up to 100"
    },
]
export {FlowyMainSection,FlowyPricingPlan}