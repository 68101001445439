import {fetchGet, fetchPost} from './CommonServiceUtils';
import AppContext from '../AppContext';

const BASE_URL = `${AppContext.baseUrl}shoutoutaiservice/billing`;

const getInvoices = () => {
    return fetchGet(`${BASE_URL}/invoices`);
};

const  payInvoice = payload => {
    return fetchPost(`${BASE_URL}/invoices/pay`, payload)
};

export {
    getInvoices,
    payInvoice
};
