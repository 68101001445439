import React from 'react';
import {Container} from 'react-bootstrap';
import BotListCard from './BotListCard';

import './PublishedBotsView.scss';

const PublishedBotsView = ({bots}) => {

  return (
    <Container className='mt-3 bot-list-view'>
    <h2>Published Flow Bots</h2>
      <div className='mt-3'>
        {bots && bots.map((flow) => (
          <>
          {flow.name && 
           <BotListCard 
                botDetails={flow}
            />
          }
          </>
        ))}
      </div>
    </Container>
  )
}

export default PublishedBotsView