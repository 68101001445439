import {fetchGet} from './CommonServiceUtils';
import AppContext from '../AppContext';

const BASE_URL = `${AppContext.baseUrl}shoutoutaiservice/flowy`;

const getPublishedFlowyBots= ({skip, limit}) => {
    return fetchGet(`${BASE_URL}/publish?skip=${skip}&limit=${limit}`);
};


export {
    getPublishedFlowyBots
};