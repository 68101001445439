import ShoutOUTAILogo from "./assets/images/logo.png";

const DefaultContext = {
    logo: ShoutOUTAILogo,
    keycloakConfig: `${process.env.PUBLIC_URL}/assets/config/${process.env.REACT_APP_KEYCLOAK_FILE_NAME}`,
    baseUrl: process.env.REACT_APP_API_BASE_URL || "",
    stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    controlHubUrl:process.env.REACT_APP_CONTROL_HUB_URL || "https://hubbeta.shoutout.ai/",
    flowyUrl: process.env.REACT_APP_FLOWY_URL || "https://flowybeta.shoutout.ai/",
    intellibotUrl: process.env.REACT_APP_INTELLIBOT_URL || "",
    helpdeskUrl: process.env.REACT_APP_HELPDESK_URL || "https://staging.helpdesk.shoutout.ai/app/",
};

let context;

switch (process.env.REACT_APP_BUILD_VARIANT) {
    default: {
        context = DefaultContext;
        break;
    }
}

export default context;
